import { Injectable } from '@angular/core';
import { Observable, combineLatest, of, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApiGatewayService, authenticationSelectors } from '@wam/authentication';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { configurationSelectors, Product, State, User } from '@wam/shared';
import { WATERFORD_STUDENT } from './waterford-apps.model';
import { WithAuthService } from './services/with-auth.service';
import {
  AssignmentBatchItem,
  AssignmentBatchItemResponse,
  AssignmentResponse,
  AssignmentStatus,
  AssignmentTimes,
  DocumentType,
  LicenseError,
  Method,
} from '@wap/catalog-v5';
import { isEmpty } from 'lodash-es';
import { Resource } from '@wap/sso-bridge';
import { AYLA_APP } from '@app/constants';

export class FailuresError extends Error {
  failures: { isLicenseError?: boolean; message: string }[];

  constructor(failures: { isLicenseError?: boolean; message: string }[]) {
    super('Could not perform assignment for some students');
    this.failures = failures;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AssignmentsService extends WithAuthService {
  constructor(
    protected store: Store<State>,
    private apiGatewayService: ApiGatewayService,
  ) {
    super(store);
  }

  getAssignmentsForLoggedInUser(): Observable<AssignmentResponse[]> {
    return this.withUser().pipe(
      switchMap((user: User) => this.getAssignments(user.organization, user.uuid)),
      take(1),
    );
  }

  getCourses(
    organization: string,
    uuid: string,
    skipRules = true,
  ): Observable<AssignmentResponse[]> {
    return this.getAssignments(organization, uuid, skipRules).pipe(
      take(1),
      map((assignments) =>
        assignments.filter(
          (a) =>
            a.document?.documentType === DocumentType.COURSE || a.document.product === Product.ayla,
        ),
      ),
    );
  }

  getAssignedDocumentsFor(uuid: string): Observable<AssignmentResponse[]> {
    return this.withUser().pipe(
      mergeMap((user) => {
        return this.getAssignments(user.organization, uuid, true, [
          AssignmentStatus.ACTIVE,
          AssignmentStatus.INACTIVE,
        ]).pipe(
          map((assignments) => {
            return assignments.filter((a) => a.document.documentType !== DocumentType.COURSE) as [];
          }),
        );
      }),
    );
  }

  removeAssignment(assignment: AssignmentResponse, app = WATERFORD_STUDENT): Observable<unknown> {
    return this.apiGatewayService.delete(
      `catalog-v5/v5` +
        `/apps/${app}` +
        `/orgs/${assignment.organization}` +
        `/users/${assignment.user}` +
        `/assignments/${assignment.id}`,
    );
  }

  getLti(assignment: AssignmentResponse, returnUrl?: string): Observable<Resource> {
    const convertedAssignment = assignment;
    const application =
      assignment.document.product === Product.ayla ? AYLA_APP : convertedAssignment.application;
    const organization = convertedAssignment.organization;
    const user = convertedAssignment.user;
    const documentId = convertedAssignment.documentId;
    const ssoProviderType = convertedAssignment.document.ssoProviderType;
    const resourceType = convertedAssignment.document.resourceType;
    const assigner =
      convertedAssignment.document.documentType === DocumentType.CURRICULET
        ? convertedAssignment.metadata?.['assigner']
        : convertedAssignment.createdUser?.split('_')?.[2];
    const school = convertedAssignment.school;
    const licenseFeatureKey = convertedAssignment.document.productFeature;
    const metadata = convertedAssignment.metadata?.['external']
      ? { resource_external: convertedAssignment.metadata['external'] }
      : undefined;

    return combineLatest([
      this.store.select(configurationSelectors.isEnabled('ADD_TOKENS_IN_LTI')),
      this.store.select(authenticationSelectors.getRefreshToken),
    ]).pipe(
      take(1),
      mergeMap(([addTokensInLtiData, userRefreshToken]) =>
        this.apiGatewayService.post<Resource>(
          `sso-bridge/v1/apps/${application}/orgs/${organization}/users/${user}/resources/${documentId}/sso${
            returnUrl ? `?returnUrl=${returnUrl}` : ''
          }`,
          {
            ssoProviderType,
            resourceType,
            assigner,
            school,
            licenseFeatureKey,
            metadata,
            userRefreshToken,
            addTokensInLtiData,
          },
          'sso',
        ),
      ),
    );
  }

  completeAssignment(documentId: string, timestamp: string): Observable<AssignmentResponse> {
    return this.withUser().pipe(
      mergeMap((user) => {
        return this.apiGatewayService
          .patch<AssignmentResponse>(
            `catalog-v5/v5/apps/${WATERFORD_STUDENT}/orgs/${user.organization}/users/${user.uuid}/documents/${documentId}/assignments`,
            {
              status: AssignmentStatus.COMPLETED,
              ...(timestamp ? { metadata: { timestamp: +timestamp } } : {}),
            },
            'assignments',
          )
          .pipe(
            catchError((error) => {
              if (error.status === 404) {
                return of({} as AssignmentResponse);
              }
              return throwError(() => error);
            }),
          );
      }),
    );
  }

  assignmentCreateUpdateDelete(
    assignmentsToCreateOrUpdate: AssignmentResponse[],
    assignmentsToDelete: AssignmentResponse[],
    schoolId: string | null = null,
    throwError: boolean = true,
  ): Observable<{
    failures?: AssignmentBatchItemResponse[];
    successes: AssignmentResponse[];
  }> {
    const createOrUpdateItems: AssignmentBatchItem[] = assignmentsToCreateOrUpdate.map(
      (assignment: AssignmentResponse) => ({
        application: WATERFORD_STUDENT,
        organization: assignment.organization,
        createItem: {
          metadata: assignment.metadata,
          school: assignment.school ?? schoolId,
          documentId: assignment.documentId,
          startDate: AssignmentTimes.IMMEDIATELY,
          endDate: AssignmentTimes.ON_COMPLETION,
          assignmentMethod: {
            method: Method.STANDALONE,
          },
        },
        user: assignment.user,
      }),
    );

    const deleteItems: AssignmentBatchItem[] = assignmentsToDelete.map(
      (assignment: AssignmentResponse) => ({
        application: WATERFORD_STUDENT,
        organization: assignment.organization,
        deleteItem: {
          assignmentId: assignment.id,
        },
        user: assignment.user,
      }),
    );

    return this.apiGatewayService
      .post<
        AssignmentBatchItemResponse[]
      >('catalog-v5/v5/batch/assignments', [...createOrUpdateItems, ...deleteItems], 'batchAssignments')
      .pipe(
        tap((response) => {
          const failures = response
            .filter((item) => item.operationStatus !== 200)
            .map((item) => ({
              isLicenseError: item.operationMessage.includes(LicenseError.ACQUIRE_RELEASE_ERROR),
              message: item.operationMessage,
            }));
          if (throwError && !isEmpty(failures)) {
            throw new FailuresError(failures);
          }
        }),
        map((response) => {
          const successes = response
            .filter((item) => item.operationStatus === 200)
            .flatMap((item) => item.assignments || []);
          const failures = response.filter((item) => item.operationStatus !== 200);
          return { successes, failures };
        }),
      );
  }

  private getAssignments(
    organization: string,
    uuid: string,
    skipRules = false,
    statuses = [AssignmentStatus.ACTIVE],
  ): Observable<AssignmentResponse[]> {
    return this.apiGatewayService.get<AssignmentResponse[]>(
      `catalog-v5/v5/apps/${WATERFORD_STUDENT}/orgs/${organization}/users/${uuid}/assignments?applyRules=${!skipRules}&statuses=${statuses.toString()}`,
    );
  }
}
